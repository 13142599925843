import { Grid, Typography, Button, TextField, Link } from "@material-ui/core";
import { Send } from "@material-ui/icons";

import React, { useState } from "react";
import { Layout } from "../components/Layout";

import { Navbar } from "../components/Navbar";

const SupportPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSend = () => {
    const encodedMessage = encodeURIComponent(`
      Hi my name is ${name}, my email is ${email} \n
      ${message}
    `);
    window.open("mailto:support@findlocalketo.com?body=" + encodedMessage);
  };

  return (
    <Layout>
      <Navbar title="Contact Us" subtitle="We'd love to hear from you!" />
      <Grid container justify="center" style={{ marginTop: "4rem" }}>
        <Grid item xs={12} lg={6}>
          <Typography style={{ textAlign: "center", marginBottom: "2rem" }}>
            Send an email to support at{" "}
            <Link href="mailto:info@findlocalketo.com">
              support@findlocalketo.com
            </Link>
          </Typography>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                fullWidth
                label="Name"
              />
            </Grid>
            <Grid item>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                fullWidth
                label="Email"
              />
            </Grid>
            <Grid item>
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant="outlined"
                fullWidth
                multiline
                label="Describe the issue you are having"
                rows={4}
                rowsMax={8}
              />
            </Grid>
            <Grid item style={{ textAlign: "right" }}>
              <Button
                onClick={handleSend}
                variant="contained"
                color="primary"
                startIcon={<Send />}
              >
                Get Help
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default SupportPage;
